/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Trans } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react';
import { constants } from '../../constants';

export default class Footer extends Component
{
  private navigation = {
    social: [
      {
        name: 'Youtube',
        href: 'https://www.youtube.com/@BinauralSensation-Isochronic',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
        ),
      },
    ],
  }

  public render(): React.ReactNode
  {
    return (
      <footer className="bg-slate-800" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="pb-8 xl:grid sm:grid-cols-4 sm:gap-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 xl:col-span-4">

              <div className="sm:mb-4">
                <h3 className="text-2xl mb-2 font-extrabold text-white">{ constants.strings.siteName }<span className="text-primary">.</span></h3>
                <p className="text-slate-400">Achieve harmony in your life.</p>
              </div>

              <div className="col-span-3 grid sm:grid-cols-3 md:gap-8">
              <div className="">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Support</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
                      <a href="https://hypnomis.freshdesk.com/support/home" target="_blank" className="text-base text-gray-300 hover:text-white">
                        <Trans>Contact</Trans>
                      </a>
                    </li>
                    <li>
                      <a href="https://hypnomis.freshdesk.com/support/home" target="_blank" className="text-base text-gray-300 hover:text-white">
                        <Trans>FAQ</Trans>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-8 sm:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
                      <a href="/blog" target="_blank" className="text-base text-gray-300 hover:text-white">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a href="https://tipi.flycricket.io/privacy.html" target="_blank" className="text-base text-gray-300 hover:text-white">
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a href="https://app.termly.io/document/terms-of-use-for-saas/2353e6b8-0cc4-47e0-825d-4eda1c19f8b7" target="_blank" className="text-base text-gray-300 hover:text-white">
                        Terms
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="pt-8 mt-4 sm:pt-0 sm:mt-0 border-t border-gray-700 sm:border-none">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  <Trans>Subscribe to our newsletter</Trans>
                </h3>
                <p className="mt-4 text-base text-gray-300">
                  <Trans>Get the latest news, articles, and resources.</Trans>
                </p>
                <a role="button" target="_blank" href="https://airtable.com/shr6GpvQtqhyiSW35" className="mt-8
                w-full bg-primary font-bold border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500">
                      <Trans>Subscribe</Trans>
                </a>

            </div>
              </div>
            </div>
          </div>
          <div className="mt-4 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {this.navigation.social.map((item) => (
                <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-300">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; { new Date().getFullYear() } { constants.strings.companyName }. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
