/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export const constants = {
  strings: {
    siteName: 'Unwind',
    companyName: 'Unwind Now',
  },
  links: {
    signUp: 'https://apps.apple.com/us/app/tipi-hypnosis-binaural-beat/id1520381656',
  }
}