/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './sections/navbar';
import Footer from './sections/footer';

const Layout = ({ children }) =>
{
  return (
    <div className="bg-white h-full text-slate-600">
      <Navbar />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
