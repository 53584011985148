/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Link, Trans } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react';
import { constants } from '../../constants';
import LanguageSwitcher from '../widgets/langage-switcher/language-switcher';

export default class Navbar extends Component
{
  public state = {
    active: false,
  };

  public componentDidMount(): void
  {
    window.addEventListener('scroll', (e) => { this.handleScroll(e) });
  };

  public componentWillUnmount(): void
  {
      window.removeEventListener('scroll', this.handleScroll);
  };

  private handleScroll(event): void
  {
      if (window.scrollY <= 10 && this.state.active)
      {
        this.setState({ active: false });
      }
      else if (window.scrollY > 10 && !this.state.active)
      {
        this.setState({ active: true });
      }
  };

  public render(): React.ReactNode
  {
    return (
      <header className={`z-10 fixed inset-x-0 ${this.state.active ? 'bg-white shadow border-b-1 border-gray-100 text-gray-800' : 'bg-transparent text-white'}`}>
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
          <div className="w-full py-4 flex items-center justify-between border-none align-middle">
            <div className="flex items-center">
              <a href="#">
                <span className="sr-only">{ constants.strings.siteName }</span>
                <h1 className="text-2xl font-extrabold">{ constants.strings.siteName }<span className="text-primary">.</span></h1>
              </a>
              <div className="hidden ml-20 space-x-12 text-lg md:block align-middle">
                <Link to="/" className="font-bold hover:text-primary">
                  <Trans>Home</Trans>
                </Link>
                <Link to="/blog" className="font-bold hover:text-primary">
                  <Trans>Blog</Trans>
                </Link>

              </div>
            </div>
            <div className="ml-10 space-x-4">
              <a
                href={constants.links.signUp}
                target="_blank"
                className="mr-8 hidden lg:inline-block bg-primary-500 py-2 px-4 border border-transparent rounded-md text-base font-bold text-white hover:bg-primary-600"
              >
                <Trans>Download</Trans>
              </a>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}
