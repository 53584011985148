/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { constants } from '../../constants';

export default class Hero extends Component
{
  public render(): React.ReactNode
  {
    return (
      <StaticQuery
        query={graphql`
          query {
            desktop: file(relativePath: { eq: "backgrounds/hero-background.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          return (
          <div className="relative overflow-hidden">
            <div className="absolute inset-y-0 h-full w-full bg-slate-900" aria-hidden="true">

            </div>

            <div className="relative py-10 mb-32">

              <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
                <div className="text-center">
                  <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-5xl">
                    <span className="block"><Trans><span className="text-slate-300">Less stress,</span> <span className='text-primary-400'>more happiness</span></Trans> 🤩</span>
                  </h1>
                  <p className="mt-3 max-w-md mx-auto text-base text-slate-400 sm:text-lg md:mt-5 md:text-2xl md:max-w-3xl">
                  <Trans>Introducing our audio therapy app <span className="font-bold">for those who cannot meditate</span>, designed to help you achieve harmony in your life.</Trans>
                  </p>
                </div>
              </div>
              <div className="mt-5 mb-8 max-w-lg mx-auto md:mt-8 p-2">
                  <h4 className='text-white text-center text-lg font-extrabold mb-4'>What can we help with?</h4>
                  <a href={constants.links.signUp} target="_blank" className="w-full text-left block bg-primary-100 font-bold rounded-lg text-lg px-6 py-3.5 hover:border-2 hover:border-primary-300 hover:bg-primary-200">😴 Fall asleep faster</a>
                  <a href={constants.links.signUp} target="_blank" className="w-full text-left block mt-4 bg-primary-100 font-bold rounded-lg text-lg px-6 py-3.5 hover:border-2 hover:border-primary-300 hover:bg-primary-200">✌️ Reduce stress or anxiety</a>
                  <a href={constants.links.signUp} target="_blank" className="w-full text-left block mt-4 bg-primary-100 font-bold rounded-lg text-lg px-6 py-3.5 hover:border-2 hover:border-primary-300 hover:bg-primary-200">🦸‍♂️ Face your fears</a>
                  <a href={constants.links.signUp} target="_blank" className="w-full text-left block mt-4 bg-primary-100 font-bold rounded-lg text-lg px-6 py-3.5 hover:border-2 hover:border-primary-300 hover:bg-primary-200">❤️ Increase self esteem</a>
                  <a href={constants.links.signUp} target="_blank" className="w-full text-left block mt-4 bg-primary-100 font-bold rounded-lg text-lg px-6 py-3.5 hover:border-2 hover:border-primary-300 hover:bg-primary-200">🕺🏼 Overcome social anxiety</a>
                  <a href={constants.links.signUp} target="_blank" className="w-full text-left block mt-4 bg-primary-100 font-bold rounded-lg text-lg px-6 py-3.5 hover:border-2 hover:border-primary-300 hover:bg-primary-200">🧠 Improve focus</a>
                </div>
            </div>


          </div>
          );
        }
      }
      />
    );
  }
}

